.menuCont {
  --background-activated: none
}

.menuCont,
.menuCont ion-content,
.menu_ionItem,
.menu_avatarCont {
  --background: #00235F;
  --color: #fff;
}

.menu_avatarCont {
  padding: 25px 0;
}

.menu_header {
  font-weight: 800;
  font-size: 30px;
  text-align: center;
  color: #FFFFFF;
  padding: 30px 0;
}

.menu_avatarCont ion-avatar,
.menu_avatarCont ion-avatar img {
  height: 75px !important;
  width: 75px !important;
}

.menu_avatarCont ion-label>h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #FFFFFF;
  margin: 0;
}

.menu_avatarCont ion-label>p {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #8290A8;
  margin: 0;
  margin-top: -5px;
}

.menu_ionItem {
  /* --background-activated: none; */
  /* padding: 7px 0; */
  /* border-bottom: 1px solid #001230; */
  border-left: 3px solid transparent;
  position: relative;
  overflow: hidden;
}

.menu_ionItem ion-icon {
  color: #fff;
}

.menu_ionItem ion-label {
  /* padding-left: 50px; */
  /* color: #7B879B !important; */
  padding: 10px 0;
  /* margin-left: 50px; */
  padding-left: 25px;
  transition: all .3s ease-in;
  color: #fff !important;
  transition: all 0.2s;
}

.menu_ionItem_active {
  color: #fff !important;
  border-color: #FAC833;
  font-weight: 600;
  letter-spacing: 0.5px;
}

@media (min-width: 992px) {
  .menuCont {
    max-width: 320px;
  }

  .menu_header {
    font-size: 33px;
  }
}

@media (max-width: 340px) {
  .menu_header {
    font-size: 28px;
  }

  .menu_ionItem ion-label {
    margin-left: 30px;
  }
}