.profile_section {
    /* background: url('../../../imgs/Truckola-webBg.png') no-repeat; */
    /* min-height: 100%; */
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) -96.98%, #F9BA00 95.57%);
    background-position: left bottom;
    background-size: cover;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    color: #000;
}

.profile_container {
    padding: 0px 100px;
    flex: 1;
}

.profile_StandardAndroidBtn_cont {
    max-width: 250px;
    margin: 30px auto;
}

/* MODAL */
.profile_truckModal_data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #D9D9D9;
}

.profile_truckModal_data:last-of-type {
    border-bottom: none;
}

.profile_truckModal_data>h3,
.profile_truckModal_data>p,
.profile_truckModal_Imgcont>h3 {
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #000000;
    margin: 0;
    margin: 15px 0;
}

.profile_truckModal_data>h3,
.profile_truckModal_Imgcont>h3 {
    padding-left: 15px;
}

.profile_truckModal_data>p {
    padding-right: 15px;
    text-transform: capitalize;
}

.profile_truckModal_Imgcont {
    margin-bottom: 15px;
}

.profile_truckModal_Imgcont>img {
    height: 250px;
    width: 100%;
    max-width: 250px;
    display: block;
    margin: auto;
    margin-top: 10px;
    box-shadow: 0px 20px 20px rgba(19, 15, 64, 0.1);
    border-radius: 15px;
    padding: 10px;
    object-fit: contain;
}

.infoModal_header {
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    color: #00235F;
    margin: auto;
    background-color: #F9BA00;
    max-width: max-content;
    padding: 5px 10px;
    border-radius: 50px;
}

.profile_truckModal_data_header {
    text-transform: capitalize;
    padding-left: 15px;
}

.profile_editTruckModal_data_header {
    padding-left: 0;
}

.profile_truckModal_data_header>p {
    font-size: 0.8rem;
    margin: 0;
    margin-top: 10px;
}

.profile_truckModal_data_header>h3 {
    font-size: 1.5rem;
    margin: 0;
    font-weight: 600;
    color: #a97f00;
}


/* FAB */
.allTrucks_fab {
    background-color: #00235F !important;
    position: fixed !important;
    right: 20px;
    bottom: 33px;
}

@media (max-width: 1200px) {
    .profile_container {
        padding: 0 25px;
    }
}

@media (max-width: 992px) {
    .profile_section {
        margin-top: 10px;
        height: calc(100vh - 60px);
        border-radius: 50px 50px 0px 0px;
        padding-top: 30px;
    }

    .profile_section_ios {
        height: calc(100vh - 105px);
    }
}

@media (max-width: 768px) {
    .profile_StandardAndroidBtn_cont {
        margin: 15px auto;
    }

    .profile_truckModal {
        --width: 100%;
        --background: transparent;
    }

    /* FAB */
    .allTrucks_fab {
        bottom: 20px;
    }
}

@media (max-width: 700px) {
    .profile_container {
        padding: 0 15px;
    }
}