.tripInfo_stepImg2 {
    background: url('../../../imgs/truckInformation/truckInfo2.svg') no-repeat center/cover;
}

.tripInfo_data_arrow2 {
    left: calc(50% - 20px);
}

@media (max-width: 650px) {
    .tripInfo_stepImg2 {
        background: url('../../../imgs/truckInformation/truckInfo2-mob.svg') no-repeat center/contain;
    }
}