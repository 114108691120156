.standardHeader_toolbar {
    --background: #fff;
    --min-height: 50px;
}

.standardHeader_toolbar ion-title {
    text-align: center;
    letter-spacing: 1px;
    font-weight: 800;
    line-height: 30px;
    color: #00235F;
}

.standardHeader_toolbar_centerTitle_desktop ion-title {
    margin-left: 10px;
}

.standardHeader_toolbar_centerTitle ion-title {
    margin-left: -40px;
}

.standardHeader_toolbar_centerTitle_with_rightIcon ion-title {
    margin-left: 0px;
}

.header_backBtn {
    width: 40px;
    padding-left: 10px !important;
}

.header_backBtn:hover,
.header_navBtn:hover,
.header_notification:hover {
    background-color: transparent !important;
}

.noBackClick>h3 {
    margin-left: 0;
}

.header_notification {
    padding-right: 12px !important;
}

.header_notification .MuiBadge-badge {
    background-color: #F9BA00;
}

@media (min-width: 992px) {
    .standardHeader_header_cont {
        display: none;
    }
}