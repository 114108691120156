.standardAndroidBtn {
    --box-shadow: none;
    --border-radius: 15px;
    --background: #00235F;
    --color: #fff;
    --color-activated: #fff;
    --color-focused: #fff;
    --color-hover: #fff;
    height: 50px;
    font-weight: 600;
    font-size: 16px;
}

.standardAndroidBtn[aria-disabled="true"] {
    opacity: .8;
}

.standardAndroidBtn ion-spinner {
    --color: #fff;
}