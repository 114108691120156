.truckInfo_stepImg3 {
    background: url('../../../imgs/truckInformation/truckInfo3.svg') no-repeat center/cover;
}

.truckInfo_data_arrow3 {
    left: unset;
    right: 5px;
}

@media (max-width: 768px) {
    .truckInfo_data_arrow3 {
        right: 10px;
    }
}

@media (max-width: 650px) {
    .truckInfo_stepImg3 {
        background: url('../../../imgs/truckInformation/truckInfo3-mob.svg') no-repeat center/contain;
    }

    .truckInfo_data_arrow3 {
        right: calc(20% - 30px);
    }
}