.calcProfit_data_cont {
    margin-top: 10px;
}

.calcProfit_calculate {
    padding: 10px 20px;
}

.calcProfit_calculate>h2,
.calcProfit_calculate>h4 {
    font-weight: 800;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #00235F;
    margin: 0;
    margin-top: 40px;
}

.calcProfit_calculate>h2 {
    margin-top: 20px;
}

.calcProfit_calculate>h4 {
    font-size: 35px;
    border-bottom: 1px solid #D9D9D9;
    max-width: 250px;
    margin: 10px auto;
    padding-bottom: 15px;
    margin-bottom: 30px;
}

.calcProfit_calculate>.standardAndroidBtn {
    margin-top: 15px;
}

.calcProfit_rangeCont>p {
    font-weight: 400;
    font-size: 15px;
    color: #101010;
    margin: 0;
}

.calcProfit_rangeCont_range {
    background: #F9F9F9;
    opacity: 0.9;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    padding: 1px 15px;
    margin-top: 12px;
}

.calcProfit_rangeCont ion-range {
    --bar-background: #F9BA00;
    --bar-background-active: #F9BA00;
    --bar-height: 12px;
    --bar-border-radius: 12px;
    --knob-background: #00235F;
    --knob-size: 30px;
    padding: 0;
}

.calcProfit_rangeCont_rangeInput {
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.calcProfit_calculate>h5,
.calcProfit_calculate>h6 {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    margin: 0;
    margin-top: 40px;
}

.calcProfit_calculate>h6 {
    border-top: 1px solid #D9D9D9;
    margin: auto;
    max-width: max-content;
    padding-top: 12px;
    margin-top: 8px;
}

.calcProfit_select {
    --padding-start: 0;
    --placeholder-color: #000;
    --placeholder-opacity: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .calcProfit_data_cont {
        border-radius: 50px;
        padding: 20px 0;
        background-color: #fff;
    }
}