.landingPage_cont {
    padding: 15px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    background-color: #fff;
}

.landing_img {
    width: 100%;
    max-width: 290px;
    height: 100%;
    max-height: 250px;
    margin: auto;
}

.landingPage_cont>div>h1 {
    font-weight: 800;
    font-size: 38px;
    line-height: 45px;
    text-align: center;
    text-transform: uppercase;
    color: #00235F;
}

.landingPage_cont>div>h4 {
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    color: #424242;
    max-width: 250px;
    margin: auto;
}

.landing_learnMore {
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    text-transform: capitalize;
    color: #00235F;
    border-bottom: 1px solid #00235F;
    max-width: 110px;
    margin: auto;
    display: block;
    margin-top: 20px;
}

.landing_btnsCont {
    margin-top: 20px;
}

.landing_btnsCont .standardAndroidBtn {
    height: 40px;
}

.landing_btnsCont_register .standardAndroidBtn {
    --background: #F9BA00;
    --background-hover: #F9BA00;
    --background-activated: #F9BA00;
    margin-top: 15px;
}

.landing_register_btn {
    --background: #F9BA00;
    --background-hover: #F9BA00;
    --background-activated: #F9BA00;
    margin-top: 15px;
}

/* MODAL  */
.learnMoreModal_toolbar {
    --border-width: 0;
    --border-style: none;
    --background: #efefef
}

.learnMoreModal_toolbar ion-title {
    font-weight: 800;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #00235F;
    letter-spacing: .5px;
}

.learnMoreModal_toolbar .learnMoreModal_titleLeft {
    text-align: left;
}