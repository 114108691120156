.truckInfo_cont {
    /* background: url('../../../imgs/Truckola-webBg.png') no-repeat; */
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) -96.98%, #F9BA00 95.57%);
    background-position: left bottom;
    background-size: cover;
    min-height: 100%;
}

.truckInfo_stepImg {
    width: 100%;
    max-width: 750px;
    height: 150px;
    margin: auto;
    display: block;
    background: url('../../../imgs/truckInformation/truckInfo1.svg') no-repeat center/cover;
}

@media (max-width: 768px) {
    .truckInfo_cont {
        background: #fff;
    }
}

@media (max-width: 600px) {
    .truckInfo_stepImg {
        background: url('../../../imgs/truckInformation/truckInfo1-mob.svg') no-repeat center/contain;
    }
}