.standard_truckCard_cont {
    display: flex;
    background: #fff;
    border-radius: 15px;
    padding: 10px 7px;
    border: 1px solid rgba(0, 35, 95, 0.1);
    box-shadow: 0px 20px 20px rgba(19, 15, 64, 0.05);
    border-radius: 15px;
    margin-bottom: 30px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.standard_truckCard_cont:last-of-type {
    margin-bottom: 15px;
}

.standard_truckCard_del,
.standard_truckCard_edit {
    padding: 0;
    --color: #98002e;
    --color-activated: var(--color);
    --color-focused: var(--color);
    --color-hover: var(--color);
    --padding-end: 0;
    --padding-start: 0;
    position: absolute;
}

.standard_truckCard_del {
    bottom: 0;
    right: 10px;
}

.standard_truckCard_edit {
    --color: darkgreen;
    --color-activated: var(--color);
    --color-focused: var(--color);
    --color-hover: var(--color);
    top: 0;
    right: 10px;
    font-size: 14px;
    border: 1px solid darkgreen;
    border-radius: 50px;
    height: 22px;
    padding: 0 10px;
}

.standard_truckCard_edit span {
    font-size: 16px;
    margin-left: 2px;
}

.standard_truckCard_del ion-spinner,
.standard_truckCard_edit ion-spinner {
    height: 20px;
    width: 20px;
}

.standard_truckCard_cont>img {
    /* width: 150px; */
    /* max-height: 120px; */
    /* object-fit: contain; */
    /* padding: 20px 0; */
    width: 250px;
    min-height: 165px;
    object-fit: cover;
    margin-right: 12px;
    border-radius: 10px;
}

.standard_truckCard_Datacont {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.standard_truckCard_cont>div>h5 {
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
    margin: 0;
}

.standard_truckCard_cont>div>p {
    font-size: 18px;
    margin: 0;
    font-weight: 500;
    text-transform: capitalize;
}

.standard_truckCard_cont>div>p>span {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #222;
    margin: 0;
    text-transform: capitalize;
}

/* SKELETON */
.standard_truckCard_cont_skeleton>.MuiSkeleton-root {
    flex: 0.3;
}

.standard_truckCard_cont_skeleton>div {
    flex: 0.7;
    margin-left: 10px;
}

@media (max-width: 700px) {
    .standard_truckCard_cont {
        margin-bottom: 15px;
    }

    .standard_truckCard_cont>img {
        min-width: 150px;
        max-width: 150px;
        min-height: 80px;
        max-height: 120px;
    }

    .standard_truckCard_cont>div>h5 {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .standard_truckCard_cont>div>p {
        font-size: 14px;
    }
}

@media (max-width: 600px) {

    .standard_truckCard_cont>img {
        /* padding: 10px 0; */
        min-width: unset;
        max-width: unset;
        min-height: unset;
        max-height: unset;
        width: 120px;
        height: 100px;
    }

    .standard_truckCard_cont>div>p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 3;
    }
}