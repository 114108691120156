.infoTemp_data_section {
    border-radius: 50px 50px 0px 0px;
    padding: 20px;
}

.infoTemp_data_cont {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.11);
    box-shadow: 0px 20px 20px rgba(19, 15, 64, 0.05);
    border-radius: 15px;
    max-width: 610px;
    margin: auto;
    padding: 25px 20px;
    padding-bottom: 40px;
    position: relative;
}

.infoTemp_data_arrow {
    position: absolute;
    top: -17px;
    left: 5px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    /* border-bottom: 20px solid #FEEDBC; */
    border-bottom: 20px solid #fff;
}

.infoTemp_data_cont>p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #00235F;
    margin: auto;
    margin-top: -10px;
    /* background-color: #FEEDBC; */
    background-color: #F9BA00;
    max-width: max-content;
    padding: 5px 10px;
    border-radius: 50px;
}

.infoTemp_inputs_cont {
    width: 100%;
    margin: auto;
    margin-bottom: 25px;
}

.infoTemp_data_cont_backcard1,
.infoTemp_data_cont_backcard2 {
    height: 20px;
    margin: auto;
    background-color: #fff;
    width: 100%;
    max-width: 570px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.11);
    box-shadow: 0px 20px 20px rgba(19, 15, 64, 0.05);
}

.infoTemp_data_cont_backcard2 {
    max-width: 520px;
}

.infoTemp_data_btns_cont {
    width: 100%;
    max-width: 400px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}

.infoTemp_data_btns_cont>ion-button {
    width: 100%;
    height: 40px;
}

.infoTemp_data_btns_cont>ion-button:first-of-type {
    margin-right: 7.5px;
}

.infoTemp_data_btns_cont>ion-button:last-of-type {
    margin-left: 7.5px;
}

@media (max-width: 768px) {
    .infoTemp_data_section {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) -96.98%, #F9BA00 95.57%);
        padding: 20px;
        margin-top: 20px;
        min-height: calc(100vh - 220px);
    }

    .infoTemp_data_cont {
        border: none;
        box-shadow: none;
        padding-bottom: 25px;
    }

    .infoTemp_data_arrow {
        top: -40px;
        left: 10px;
        border-bottom: 20px solid #F9BA00;
    }

    .infoTemp_data_cont_backcard1,
    .infoTemp_data_cont_backcard2 {
        border: none;
        box-shadow: none;
        opacity: .4;
    }

    .infoTemp_data_cont_backcard1 {
        margin-top: -5px;
    }

    .infoTemp_data_cont_backcard2 {
        margin-top: -15px;
        height: 30px;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        max-width: 550px;
    }
}

@media (max-width: 650px) {
    .infoTemp_data_arrow {
        left: calc(15% - 30px);
    }

    .infoTemp_data_cont_backcard1,
    .infoTemp_data_cont_backcard2 {
        max-width: calc(100% - 30px);
    }

    .infoTemp_data_cont_backcard2 {
        max-width: calc(100% - 50px);
    }
}