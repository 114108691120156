.tripInfo_stepImg3 {
    background: url('../../../imgs/truckInformation/truckInfo3.svg') no-repeat center/cover;
}

.tripInfo_data_arrow3 {
    left: unset;
    right: 5px;
}

.tripInfo3_previewImg {
    height: 250px;
    width: 100%;
    max-width: 250px;
    margin: auto;
    margin-top: 10px;
    box-shadow: 0px 20px 20px rgba(19, 15, 64, 0.1);
    border-radius: 15px;
    padding: 10px;
}

.tripInfo3_previewImg>ion-icon {
    cursor: pointer;
    font-size: 18px;
    display: block;
    margin-left: auto;
    background-color: #fff;
    border-radius: 50px;
    padding: 5px;
}

@media (max-width: 768px) {
    .tripInfo_data_arrow3 {
        right: 10px;
    }
}

@media (max-width: 650px) {
    .tripInfo_stepImg3 {
        background: url('../../../imgs/truckInformation/truckInfo3-mob.svg') no-repeat center/contain;
    }

    .tripInfo_data_arrow3 {
        right: calc(20% - 30px);
    }
}