.myDocuments_section {
    padding: 10px 0;
}

.myDocumentsList_cont .MuiButtonBase-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
}

.myDocumentsList_cont .MuiButtonBase-root>span {
    font-size: 22px;
    font-weight: 500;
}

@media (max-width: 991px) {
    .myDocuments_section {
        background-color: #fff;
        display: block;
        min-height: max-content;
    }
}