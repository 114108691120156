.homePage_card {
    width: 220px;
    height: 200px;
    background-color: #fff;
    border-radius: 15px;
    padding: 15px;
    padding-top: 30px;
    display: grid;
    place-items: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.homePage_card_greyBg {
    background-color: rgba(241, 241, 241, 0.71);
}

.homePage_card>img {
    height: 70px;
    width: 70px;
}

.homePage_card>p {
    font-weight: 600;
    font-size: 17px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    margin: 0;
    margin-top: 15px;
}

@media (max-width: 550px) {
    .homePage_card {
        width: 100%;
        height: 100%;
        max-width: 170px;
        max-height: 150px;
    }

    .homePage_card>img {
        height: 50px;
        width: 50px;
    }

    .homePage_card>p {
        font-size: 14px;
    }
}