.changepass_cont {
    /* background: url("../../imgs/login-signUp-sun.png") no-repeat; */
    /* background-position-x: 50%;
    background-size: cover; */
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) -25.63%, #F9BA00 95.57%);
    border-top-left-radius: 70px;
    border-top-right-radius: 70px;
    padding: 50px 20px;
    padding-top: 100px;
    min-height: calc(100% - 141px);
}

.changepass_data_cont {
    background-color: #fff;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    padding-top: 10px;
    border-radius: 30px;
    margin: auto;
}

.changepass_data_cont>p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #727272;
    text-align: center;
    margin: 25px 0;
}

.changepass_data_cont>ion-button {
    --box-shadow: none;
    --background: #00235F;
    --background-hover: #00235F;
    color: #fff;
    margin-top: 20px;
}

