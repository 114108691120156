.infoModal_dash {
    height: 5px;
    width: 100%;
    max-width: 250px;
    margin: auto;
    border-radius: 100px;
    background-color: #00235F;
    margin-bottom: 20px;
}

.infoModal_closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    color: #00235F;
}

@media (max-width: 768px) {
    .infoModal_dialog .MuiPaper-root {
        margin: 0;
        margin-top: auto;
        width: 100%;
        border-radius: 0;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
    }
}