.reviewInputs_cont {
    padding: 30px 20px;
}

.reviewInputs_cont .infoTemp_data_cont {
    max-width: unset;
}

.reviewInputs_cont .infoTemp_data_cont_backcard1,
.reviewInputs_cont .infoTemp_data_cont_backcard2,
.reviewInputs_cont .infoTemp_data_arrow {
    display: none;
}

.reviewInputs_Imgcont>h3 {
    padding-left: 20px;
}

.reviewInputs_Imgcont>img {
    height: 250px;
    width: 100%;
    max-width: 250px;
    display: block;
    margin: auto;
    margin-top: 10px;
    box-shadow: 0px 20px 20px rgba(19, 15, 64, 0.1);
    border-radius: 15px;
    padding: 10px;
    object-fit: contain;
}

/* ----------------------------------------------------------------------------------------------- */

.truckInfo_reviewData_cont {
    border: 1px solid #DBDBDB;
    border-radius: 10px;
}

.truckInfo_review_data {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.truckInfo_review_data {
    border-bottom: 1px solid #D9D9D9;
}

.truckInfo_review_data>h3,
.truckInfo_review_data>p,
.reviewInputs_Imgcont>h3,
.truckInfo_review_data_header>p {
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #000000;
    margin: 0;
    margin: 15px 0;
}

.truckInfo_review_data>h3 {
    padding-left: 15px;
    min-width: 200px;
}

.truckInfo_review_data>p {
    padding-right: 15px;
    text-transform: capitalize;
}

.truckInfo_review_data_header {
    text-transform: capitalize;
    padding-left: 15px;
}

.truckInfo_review_data_header>p {
    font-size: 0.8rem;
    margin: 0;
    margin-top: 10px;
}

.truckInfo_review_data_header>h3 {
    font-size: 1.5rem;
    margin: 0;
    font-weight: 600;
    color: #a97f00;
}

@media (max-width: 768px) {
    .reviewInputs_cont {
        min-height: calc(100vh - 70px);
        padding: 0;
    }

    .reviewInputs_cont .infoTemp_data_section {
        margin-top: 10px;
        min-height: calc(100vh - 60px);
    }
}

@media (max-width: 340px) {
    .reviewInputs_cont .infoTemp_data_cont {
        padding: 25px 10px;
    }

    .truckInfo_review_data>h3 {
        font-size: 13px;
    }
}