.noData_img_cont {
    text-align: center;
    background-color: #eee;
    padding: 20px;
    border-radius: 30px;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 280px;
    margin: auto;
}

.noData_img_cont>img {
    height: 75px;
    object-fit: contain;
}

.noData_img_cont>h3 {
    font-size: 1.3rem;
}

@media(max-width: 991px) {
    .noData_img_cont {
        margin: auto;
    }
}