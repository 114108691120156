.homePage_section {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) -25.63%, #F9BA00 95.57%);
    min-height: 100%;
    padding: 30px 15px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.homePage_container {
    max-width: 550px;
    width: 100%;
}

.homePage_cont_sec1,
.homePage_cont_sec2,
.homePage_cont_sec3_cardCont {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 15px;
    place-items: center;
}

.homePage_cont_sec1,
.homePage_cont_sec2 {
    max-width: 480px;
    margin: auto;
}

.homePage_cont_sec2 {
    margin-top: 20px;
}

.homePage_cont_sec3 {
    background-color: #fff;
    padding: 30px;
    border-radius: 15px;
    margin-top: 20px;
}

.homePage_cont_sec3>h3 {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    margin: 0;
}

.homePage_cont_sec3>p {
    font-weight: 400;
    font-size: 11px;
    line-height: 25px;
    color: #747474;
    margin: 0;
    margin-bottom: 15px;
}

.homePageContactUs_fab {
    background-color: #fff !important;
    box-shadow: none !important;
    padding: 10px !important;
    height: 50px !important;
    width: 50px !important;
    position: fixed !important;
    right: 20px;
    bottom: 30px;
}

.homePage_SubscribeModal_cont>img {
    height: 100%;
    width: 100%;
    max-height: 400px;
    max-width: 400px;
    display: block;
    margin: auto;
    margin-top: -50px;
}

.homePage_SubscribeModal_cont>h3 {
    text-align: center;
    margin: 0;
    margin-top: -35px;
    font-size: 20px;
    color: #00235F;
    padding-bottom: 10px;
    font-weight: 500;
}

@media (max-width: 550px) {
    .homePage_section {
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        margin-top: 10px;
        min-height: calc(100% - 10px);
    }

    .homePage_cont_sec1,
    .homePage_cont_sec2 {
        max-width: 380px;
    }
}