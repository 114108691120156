.subscription_cont {
    background-color: #fff;
}

.subscription_section {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) -96.98%, #F9BA00 95.57%);
    min-height: 100vh;
    padding: 20px;
    display: grid;
    place-items: center;
}

.subscription_data {
    background-color: #fff;
    padding: 20px;
    padding-bottom: 0;
    border-radius: 20px;
    max-width: fit-content;
}

.subscription_data>h6 {
    color: #00235F;
    margin: 0;
}

.subscription_data>h3 {
    margin: 0;
    font-weight: 600;
}

.subscription_data_card_cont {
    margin-bottom: 30px;
    display: flex;
}

.subscription_data_card1_list>p {
    margin: 0;
    margin-top: 5px;
    padding-left: 20px;
}

.subscription_data_card1_list>p::before {
    content: "\2022";
    color: #00235F;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.subscription_data_card_active .subscription_data_card1_list>p::before {
    color: #fff;
}

.subscription_data_card1 {
    background-color: #fff;
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0 0 10px #00000040;
    margin-right: 20px;
    cursor: default;
    display: block;
}

.subscription_data_card1:last-of-type {
    margin-right: 0;
}

.subscription_data_card_active {
    background-color: #00235F;
    color: #fff;
}

.subscription_data_card1>h4 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 3px;
}

.subscription_data_card1>h6 {
    margin: 0;
    font-size: 16px;
    margin-bottom: 15px;
}

.subscription_data_card1_expiryCont>p {
    margin: 0;
    margin-top: 20px;
    text-align: right;
    font-weight: 500;
    font-size: 1.05rem;
    color: #F9BA00;
}

.subscription_data_card_goPremium,
.subscription_data_card_active .subscription_data_card_goPremium {
    font-size: 14px;
    color: #00235F;
    margin-bottom: 3px;
}

.subscription_data_card2>h4 {
    margin-bottom: 15px;
}

.subscription_data_card_active .subscription_data_card_goPremium {
    color: #F9BA00;
}

.subscription_paypal_btns {
    max-width: 650px;
    margin: auto;
}

/* Cancel Modal */
.subscription_cancelModal_cont>img {
    height: 100%;
    width: 100%;
    max-height: 400px;
    max-width: 400px;
    display: block;
    margin: auto;
}

.subscription_cancelModal_cont>h3 {
    text-align: center;
    margin: 0;
    margin-top: -35px;
    font-size: 20px;
    color: #e3342f;
    padding-bottom: 10px;
    font-weight: 500;
}

.subscription_successModal_cont>img {
    margin-top: -25px;
}

.subscription_successModal_cont>h3 {
    color: darkgreen;
    margin-top: -25px;
}

.subscription_unsubscribeBtn {
    margin: auto !important;
    margin-bottom: 20px !important;
    margin-top: -5px !important;
    border-radius: 50px !important;
    max-width: 500px;
    display: block !important;
    background-color: #00235F !important;
}

@media (max-width: 992px) {
    .subscription_section {
        padding-top: 10px;
        min-height: calc(100vh - 60px);
        margin-top: 10px;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
    }
}

@media (max-width: 768px) {
    .subscription_data_card_cont {
        flex-direction: column-reverse;
    }

    .subscription_data_card1 {
        margin-right: 0;
    }

    .subscription_data_card2 {
        margin-bottom: 25px;
    }

    .subscription_cancelModal_cont>h3 {
        font-size: 14px;
    }

    .subscription_successModal_cont>h3 {
        font-size: 18px;
    }
}