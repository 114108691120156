.editModal_dash {
    height: 5px;
    width: 100%;
    max-width: 250px;
    margin: auto;
    border-radius: 100px;
    background-color: #00235F;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .editModal_dialog .MuiPaper-root {
        margin: 0;
        margin-top: auto;
        width: 100%;
        max-width: unset;
        border-radius: 0;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
    }
}