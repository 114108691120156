.login_cont {
    padding: 50px 20px;
    padding-top: 100px;
    min-height: calc(100vh - 141px);
    display: grid;
    place-items: center;
    /* background: url("../../imgs/login-signUp-sun.png"); */
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) -25.63%, #F9BA00 95.57%);
    border-top-left-radius: 70px;
    border-top-right-radius: 70px;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-size: cover;
}

.login_data_cont {
    background-color: #fff;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    padding-top: 10px;
    border-radius: 30px;
    margin: auto;
}

.login_btnCont {
    max-width: 350px;
    margin: auto;
    margin-top: 20px;
}

.login_btnCont>ion-button {
    height: 45px;
}

.login_forgotPass {
    font-weight: 400;
    font-size: 15px;
    line-height: 32px;
    display: block;
    max-width: 180px;
    margin: 5px auto;
    margin-bottom: 30px;
    text-transform: capitalize;
    color: #00235F;
    border-bottom: 1px solid #00235F;
}

.login_newUser_btn,
.login_newUser_text {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #000000;
    display: block;
    display: block;
    max-width: 170px;
    margin: 15px auto;
    /* border-bottom: 1px solid #333; */
}

.login_newUser_text {
    border: 0;
    max-width: 110px;
    margin-top: -7px;
}

.login_footer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    background: rgba(249, 186, 0, 0.5);
    height: 40px;
}


@media (max-width: 991px) {
    .login_cont {
        min-height: calc(100vh - 191px);
    }
}

/* @media (max-width: 768px) {
    .login_cont {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) -25.63%, #F9BA00 95.57%);
        border-top-left-radius: 70px;
        border-top-right-radius: 70px;
    }
} */