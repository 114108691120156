.services_cont {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) -25.63%, #F9BA00 95.57%);
    height: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.services_section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    row-gap: 20px;
    column-gap: 20px;
}

.services_alert .alert-wrapper,
.services_alert .alert-button {
    background-color: #00235F !important;
}

.services_alert .alert-head>h2 {
    color: #F9BA00;
}

.alert-button-ok {
    color: #fff !important;
}

@media (max-width: 991px) {
    .services_cont {
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        margin-top: 10px;
        height: calc(100% - 10px);
    }
}

@media (max-width: 550px) {
    .services_section {
        width: 100%;
        max-width: 300px;
    }
}