.dispatchFeeTypeToggle {
    display: flex;
    align-items: center;
    max-width: max-content;
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: -50px;
}

.dispatchFeeTypeToggle>h2 {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    margin-top: 5px;
}

.dispatchFeeTypeToggle ion-toggle {
    margin: 0 10px;
    --background: #00235F;
    --background-checked: #e7af08;
}