.myDocs_container {
    /* background: url('../../../imgs/Truckola-webBg.png') no-repeat; */
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) -25.63%, #F9BA00 95.57%);
    background-position: left bottom;
    background-size: cover;
    padding: 20px;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.myDocs_section {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.11);
    box-shadow: 0px 20px 20px rgba(19, 15, 64, 0.05);
    border-radius: 15px;
    padding: 20px;
    width: 100%;
    max-width: 800px;
    margin: auto;
}

.myDocs_docCard_cont {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    place-items: center;
    grid-column-gap: 20px;
    grid-row-gap: 30px;
}

/* CARD */
.myDocs_docCard {
    width: 150px;
    min-width: 150px;
    height: 140px;
    text-align: center;
    background-color: #fff;
    border-radius: 20px;
    padding: 10px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.myDocs_docCard>img {
    width: 70px;
    height: 70px;
}

.myDocs_docCard>h4 {
    margin: 0;
    font-weight: 500;
    font-size: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@media (max-width: 991px) {
    .myDocs_container {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) -25.63%, #F9BA00 95.57%);
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        margin-top: 10px;
        min-height: calc(100% - 10px);
        display: block;
    }

    .myDocs_section {
        margin-top: 0;
        background: transparent;
        border: none;
        box-shadow: none;
        padding: 0;
        min-height: calc(100vh - 120px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}