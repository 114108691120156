.allDocs_docCard_cont {
    grid-template-columns: repeat(2, 1fr);
}

.allDocs_docCard_cont .myDocs_docCard {
    width: 100%;
    height: 100%;
}

.allDocs_docCard_cont .myDocs_docCard>img {
    height: 200px;
    width: 100%;
    max-width: 200px;
    object-fit: contain;
}

@media (max-width: 991px) {
    .allDocs_docCard_cont {
        display: block;
    }
    
    .myDocs_docCard {
        margin-bottom: 20px;
    }
    
    .myDocs_docCard:last-of-type {
        margin-bottom: 0;
    }
}

@media (max-width: 500px) {

    .allDocs_docCard_cont .myDocs_docCard>img {
        height: 150px;
    }
}