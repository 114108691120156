.viewDocs_section {
    max-width: 900px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 25px;
    place-items: center;
}

.viewDocs_addFab {
    position: fixed !important;
    bottom: 20px;
    right: 20px;
    background-color: #00235F !important;
    font-size: 35px !important;
}

.viewDocs_addFab>ion-icon {
    font-size: 25px;
    color: #fff;
}

.viewDocs_addFab>p {
    font-size: 16px;
    color: #fff;
    padding-left: 5px;
}

/* CARD */
.viewDocumentCard {
    background-color: #fff;
    width: 100%;
    height: 100%;
    max-height: 280px;
    padding: 15px;
    border-radius: 10px;
    overflow: hidden;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    cursor: pointer;
}

.viewDocumentCard_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.viewDocumentCard_header>h3 {
    margin: 0;
    font-size: 1.1rem;
}

.viewDocumentCard_header>ion-icon {
    font-size: 18px;
    cursor: pointer;
}

.viewDocumentCard>img {
    width: 100%;
    height: 100%;
    max-height: 205px;
    object-fit: contain;
    border-radius: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
}

/* IMG Dialog */
.viewDocs_imgDialog_closeIcon {
    font-size: 50px;
    position: absolute;
    right: 15px;
    top: 20px;
    background-color: #fff;
    cursor: pointer;
    border-radius: 50%;
}

.viewDocs_imgDialog_img {
    margin-top: 25px;
    height: calc(100% - 25px);
    width: 100%;
    padding: 0 15px;
    object-fit: contain;
}

/* UPLOAD DIalog */
.viewDocs_uploadDialog .MuiPaper-root {
    max-width: 768px;
}

.viewDocs_uploadDialog_btn {
    max-width: 300px;
    display: block !important;
    margin: auto !important;
    margin-top: 20px !important;
    text-align: center;
    color: #fff !important;
    background-color: #00235F !important;
}

.viewDocs_uploadDialog_previewImg {
    width: 100%;
    height: 100%;
    max-height: 330px;
    object-fit: contain;
    margin-top: 20px;
}

@media(max-width: 991px) {
    .viewDocs_section {
        display: flex;
        grid-template-columns: none;
        justify-content: flex-start;
    }
}

@media(max-width: 768px) {
    .viewDocumentCard {
        max-height: 280px;
    }

    .viewDocs_imgDialog_closeIcon {
        font-size: 35px;
    }
}