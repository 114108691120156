.account_cont {
    background: url('../../imgs/Truckola-webBg.png') no-repeat;
    background-position: left bottom;
    background-size: cover;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
}

.account_avatar_sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.account_avatarCont {
    position: relative;
    cursor: pointer;
}

.account_avatarCont ion-avatar {
    height: 100px;
    width: 100px;
}

.account_avatarCont ion-icon {
    position: absolute;
    top: -5px;
    left: -7px;
    height: 17px;
    width: 17px;
    background: #F2F2F2;
    border: 2.5px solid #FFFFFF;
    border-radius: 50%;
    padding: 7px;
}

.account_avatarCont .MuiCircularProgress-root {
    position: absolute;
    top: calc(50% - 17px);
    left: calc(50% - 17px);
    background-color: #00235F;
    border-radius: 50px;
    padding: 5px;
}

.account_avatar_sec>h3 {
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #00235F;
    margin: 0;
    margin-top: 15px;
    text-transform: capitalize;
}

.account_avatar_sec>p {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #000000;
    margin: 0;
}

.account_data_sec {
    border-radius: 50px 50px 0px 0px;
    padding: 20px;
    padding-bottom: 20px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.11);
    box-shadow: 0px 20px 20px rgba(19, 15, 64, 0.05);
    border-radius: 15px;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    margin-top: 20px;
}

.accountBtns_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    margin-bottom: 15px;
    max-width: 650px;
}

.accountBtns_section>button {
    color: #F9BA00;
    border: 1px solid rgba(249, 186, 0, 0.5);
    width: 100%;
    max-width: 215px;
}

.accountBtns_section>button:hover {
    border: 1px solid rgba(249, 186, 0, 0.5) !important;
    background-color: rgba(249, 186, 0, 0.04);
}

/* LOGOUT Button */
.account_data_sec ion-button {
    max-width: 320px;
    margin: auto;
    margin-top: 25px;
}

.accountBtns_section_2>ion-button {
    --color: #00235F;
    margin: unset;
    font-size: 14px;
    display: block;
    max-width: max-content;
}

/* DELETE Button Cont*/
.accountBtns_section_2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 7px;
}

.account_delteBtn {
    color: rgba(0, 0, 0, 0.25) !important;
}

/* Skeleton */
.account_skeleton {
    width: 100%;
}

.account_skeleton .account_data_sec {
    margin-top: 50px;
    padding: 20px;
}

.account_editModal_alert {
    margin-top: 20px !important;
    border-radius: 0 !important;
}

/* DELETE MODAL */
.account_deleteAcc_modal_cont>h4 {
    margin: 0;
    margin-bottom: 25px;
    margin-top: 5px;
}

.account_deleteAcc_modal_cont>p {
    margin: 0;
    margin-bottom: 7px;
}

/* @media (max-width: 992px) {
    .account_cont {
        min-height: calc(100% - 50px);
    }
} */


@media (max-width: 450px) {
    .account_cont {
        padding: 0;
        display: block;
        background: #fff;
        min-height: unset;
    }

    .account_avatar_sec {
        margin-top: 20px;
    }


    .account_data_sec {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) -96.98%, #F9BA00 95.57%);
        min-height: calc(100vh - 235px);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding: 20px 15px;
        padding-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .account_data_sec_inputCont {
        background-color: #fff;
        padding: 20px;
        border-radius: 20px;
    }

    .account_data_sec ion-button {
        max-width: unset;
        width: 100%;
        display: block;
        margin: unset;
        margin-top: 25px;
    }

    .accountBtns_section_2 {
        margin-bottom: -10px;
    }

    .accountBtns_section_2>ion-button {
        margin: unset;
        margin-left: auto;
        font-size: 14px;
        display: block;
        max-width: max-content;
    }

    /* SKELETON */
    .account_skeleton {
        display: flex;
    }

    .account_skeleton .account_data_sec>.MuiSkeleton-root {
        margin: 0 auto;
        margin-top: auto;
    }

    .account_skeleton .account_data_sec_inputCont>.MuiSkeleton-root {
        height: 50px !important;
        margin-top: 20px;
    }

    .account_skeleton .account_data_sec_inputCont>.MuiSkeleton-root:first-of-type {
        margin-top: 0;
    }
}