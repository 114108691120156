.myTrip_select {
    --padding-start: 0;
    --placeholder-color: #000;
    --placeholder-opacity: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.myTripInfo_date {
    max-width: 350px;
    margin: auto;
    margin-top: 20px;
}

.myTripInfo_date>ion-button {
    --background: #FEEDBC;
    --color: '#000';
}