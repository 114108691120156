.notifications_cont {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) -25.63%, #F9BA00 95.57%);
    background-position: left bottom;
    background-size: cover;
    padding: 20px;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notifications_section {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.11);
    box-shadow: 0px 20px 20px rgba(19, 15, 64, 0.05);
    border-radius: 15px;
    padding: 20px;
    width: 100%;
    max-width: 800px;
    margin: auto;
}

.notifications_section>.MuiCircularProgress-root {
    color: #00235F;
    display: block;
    margin: auto;
}

.notifications_notFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.notifications_notFound>img {
    width: 100%;
    max-width: 500px;
}

.notifications_notFound>h3 {
    font-size: 1.5rem;
    margin: 0;
    color: #00235F;
}

/*  */
.notifications_data_cont {
    background-color: #00235fc4;
    padding: 15px;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 15px;
}

.notifications_data_cont_read {
    background-color: gray !important;
}

.notifications_data_cont:last-of-type {
    margin-bottom: 0px;
}

.notifications_heading_cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.notifications_heading_cont>h2 {
    font-size: 1.25rem;
    font-weight: 500;
    color: #fff;
    margin: 0;
    line-height: 30px;
}

.notifications_heading_cont ion-icon {
    padding-left: 20px;
}

.notifications_subHeading_cont>p {
    margin-top: 15px !important;
    font-size: 1rem;
    color: #ddd;
    margin: 0;
    white-space: pre-line;
}

.notifications_data_cont .notifications_subHeading_cont {
    max-height: 0;
    transition: max-height 0.75s ease-out;
    overflow: hidden;
}

.notifications_subHeading_cont_active {
    max-height: 5000px !important;
    transition: max-height 1.5s ease-in !important;
}

@media (max-width: 768px) {
    .notifications_notFound {
        display: flex;
        min-height: calc(100vh - 100px);
    }

    .notifications_notFound>img {
        border-radius: 20px;
        margin-bottom: 15px;
    }

    .notifications_cont {
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        margin-top: 10px;
        min-height: calc(100% - 10px);
        display: block;
    }

    .notifications_section {
        background-color: transparent;
        border: none;
        box-shadow: none;
        padding: 0;
    }

    .notifications_data_cont {
        background-color: #00235F;
    }
}