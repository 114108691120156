.signup_logo {
    width: 287px;
    height: 191px;
    margin: auto;
    display: block;
    margin-top: 20px;
    margin-bottom: -70px;
}

.signup_cont {
    padding: 50px 0;
    padding-top: 100px;
    min-height: calc(100vh - 141px);
    display: grid;
    place-items: center;
    /* background: url("../../imgs/login-signUp-sun.png"); */
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) -25.63%, #F9BA00 95.57%);
    border-top-left-radius: 70px;
    border-top-right-radius: 70px;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-size: cover;
}

.signup_dataCont {
    background-color: #fff;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    padding-top: 10px;
    border-radius: 30px;
    margin: auto;
}

.signup_dataCont>ion-button {
    margin-top: 20px;
}

.signup_dataCont>p {
    text-align: center;
    color: #818181;
    margin: 20px 0;
    margin-bottom: 15px;
}

.signup_logoCont {
    display: flex;
    align-items: center;
    justify-content: center;
}

.signup_logoCont>ion-button {
    background: rgba(241, 241, 241, 0.71);
    border: 2px solid rgba(236, 236, 236, 0.42);
    border-radius: 15px;
    color: #000000;
}

.signup_logoCont>ion-button:first-child {
    margin-right: 10px;
}

.signup_logoCont>ion-button:last-child {
    margin-left: 10px;
}

.signup_footer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    background: rgba(249, 186, 0, 0.5);
    height: 40px;
}

/* Checkbox */
.signup_checkbox_cont {
    display: flex;
    margin-top: 10px;
    align-items: flex-start;
}

.signup_checkbox_cont>.MuiCheckbox-root {
    color: #00235F !important;
}

.signup_checkbox_cont>p {
    font-size: 12px;
    color: #00235F;
    text-decoration: underline;
    cursor: pointer;
    margin: 0;
}


@media (max-width: 991px) {
    .signup_cont {
        min-height: calc(100vh - 191px);
        padding-bottom: 20px;
    }
}

/* @media (max-width: 768px) {
    .signup_cont {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) -25.63%, #F9BA00 95.57%);
        border-top-left-radius: 70px;
        border-top-right-radius: 70px;
    }
} */

@media (max-width: 500px) {
    .signup_dataCont {
        width: calc(100% - 40px);
    }
}