.profile_container_filterData {
    background-color: #00235faf;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;
    color: rgba(255, 255, 255, 0.816);
}

.profile_container_filterData_header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.profile_container_filterData_header>h2 {
    margin: 0;
    margin-bottom: 15px;
    color: #fff;
}

.profile_container_filterData_header>span {
    font-size: 25px;
    font-weight: 700;
    cursor: pointer;
}

.profile_container_filterData>h4 {
    margin: 0;
    margin-top: 7px;
}

.profile_container_filterData>h4>span {
    font-size: 1.1rem;
    text-transform: capitalize;
}

.myTrips_filterModal .MuiPaper-root {
    max-width: 768px;
}

.myTrips_filterModal .MuiDialogContent-root {
    padding-bottom: 25px !important;
}

.myTrips_date {
    max-width: 350px;
    width: 100%;
    margin: auto;
    margin-top: 20px;
}

.myTrips_date>ion-button {
    --background: #FEEDBC;
    --color-hover: #000;
    --color-activated: #000;
    --color: '#000';
}

.myTrips_filterModal_checkBox {
    color: #00235F !important;
}

.myTrips_standardTruckCard>h3 {
    margin: 0;
    margin-bottom: 10px;
    font-size: 1.4rem;
}

/* EDIT MODAL */
.myTrips_uploadDialog_btn {
    max-width: 300px;
    display: block !important;
    margin: auto !important;
    margin-top: 20px !important;
    text-align: center;
    color: #fff !important;
    background-color: #00235F !important;
}

.myTrips_previewImg {
    height: 250px;
    width: 100%;
    max-width: 250px;
    margin: auto;
    margin-top: 10px;
    box-shadow: 0px 20px 20px rgba(19, 15, 64, 0.1);
    border-radius: 15px;
    padding: 10px;
}

.myTrips_previewImg>ion-icon {
    cursor: pointer;
    font-size: 18px;
    display: block;
    margin-left: auto;
    background-color: #fff;
    border-radius: 50px;
    padding: 5px;
}

@media (max-width: 700px) {
    .myTrips_standardTruckCard>h3 {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 280px;
    }
}