/* T&C GLobal Classes */

.tc_cont_mail {
    color: #00235F;
    cursor: pointer;
    font-weight: 500;
    opacity: 0.9;
}

.tc_nomargin {
    margin: 0;
}

.tc_u {
    border-bottom: 1px solid #000;
    padding-bottom: 0.5px;
}

.tc_b {
    font-weight: 600;
}

/* */

.tc_cont {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) -25.63%, #F9BA00 95.57%);
    padding: 20px 15px;
}

.tc_sec1_header,
.tc_sec3_header {
    font-size: 1.5rem;
    font-weight: 600;
    color: #00235F;
}

.tc_sec3_header {
    margin-top: 40px;
}

.tc_sec1_1,
.tc_sec1_2,
.tc_sec1_3,
.tc_sec1_4,
.tc_sec1_5,
.tc_sec1_6,
.tc_sec2_dataCont_data,
.tc_li {
    margin-top: 10px;
    font-size: 0.95rem;
}

.tc_sec2_dataCont,
.tc_sec3_dataCont {
    margin-top: 25px;
}

.tc_sec2_header {
    font-size: 1.1rem;
    font-weight: 600;
    color: #00235F;
    opacity: 0.9;
}