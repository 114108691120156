.payDriver_cont {
    /* background: url('../../imgs/Truckola-webBg.png') no-repeat; */
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) -25.63%, #F9BA00 95.57%);
    background-position: left bottom;
    background-size: cover;
    padding: 20px;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.payDriver_section {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.11);
    box-shadow: 0px 20px 20px rgba(19, 15, 64, 0.05);
    border-radius: 15px;
    padding: 20px;
    width: 100%;
    max-width: 800px;
    margin: auto;
}

.payDriver_data_cont_card1,
.payDriver_data_cont_card2 {
    background-color: #fff;
    padding: 40px 20px;
    padding-top: 30px;
    border-radius: 20px;
    margin-top: 30px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 30px;
}

.payDriver_data_cont_card1 {
    margin-top: 0;
}

.payDriver_data_cont_card1 .inputWithLabel_inputCont {
    margin-top: 13px;
}

.payDriver_data_cont_links {
    margin-top: 15px;
    padding: 0 20px;
}

.payDriver_data_cont_links>h3 {
    font-size: 16px;
    margin: 0;
}

.payDriver_data_cont_links>a {
    text-decoration: none;
    display: block;
    margin-top: 7px;
    color: #00235F;
    font-size: 14px;
    border-bottom: 1px solid #00235F;
    max-width: max-content;
}

.payDriver_btns_cont {
    width: 100%;
    max-width: 400px;
    margin: auto;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}

.payDriver_btns_cont>ion-button {
    width: 100%;
    --background: #00235F
}

.payDriver_btns_cont>ion-button:first-of-type {
    margin-right: 7.5px;
}

.payDriver_btns_cont>ion-button:last-of-type {
    margin-left: 7.5px;
}

.payDriver_noTrucksModal_cont {
    padding-bottom: 10px;
}

.payDriver_driverPayHeading {
    font-size: 16px;
    text-align: center;
    margin: 0;
    border-top: 1px solid #322e2e75;
    color: #444;
    margin-top: 30px;
    padding-top: 10px;
}

.payDriver_driverPayDash {
    height: 1px;
    width: 100%;
    background-color: #322e2e75;
    margin-top: 30px;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .payDriver_cont {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) -25.63%, #F9BA00 95.57%);
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        margin-top: 10px;
        min-height: calc(100% - 10px);
        display: block;
    }

    .payDriver_section {
        margin-top: 0;
        background: transparent;
        border: none;
        box-shadow: none;
        padding: 0;
        min-height: calc(100vh - 120px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .payDriver_data_cont_card1,
    .payDriver_data_cont_card2 {
        border: none;
    }

    .payDriver_data_cont_card2 {
        margin-top: 20px;
    }

    .payDriver_btns_cont {
        margin: 0 auto;
        margin-top: 30px;
    }
}